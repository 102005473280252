import './Tile.css';
import { Link } from 'react-router-dom';

const Tile = ({event}) => {
    return (
    <div className="block">
        <Link to={`/${event.folderName}`}>
            <img src={event.img} alt="{event.tileName}" loading="lazy"/>
            <h2 className="name">{event.tileName}</h2>
        </Link>
    </div>
    )
}

export default Tile;
import { Link } from 'react-router-dom';
import './PayPage.css';

const PayPage = () => {
    return (
        <main className='cc-uf-main'>
            <h1>PayPal/Venmo Payment for concerts</h1>            
            <h2>Steps to pay using PayPal/Venmo</h2>
            <ul>
                <li>Open PayPal/Venmo app</li>
                <li>Select Send button</li>
                <li>Specify teamcadenceentertainment@gmail.com for PayPal</li>
                <li>Specify @CadenceEntertainment for Venmo</li>
                <li>Enter ticket amount USD 25 for single concert ticket or a custom amount authorized by Cadence Entertainment or USD 150 for the 12 concert annual pass.</li> 
                <li>Click Pay button</li> 
                <li>For additional confirmation and ticket issuance, after making payment, please send your name, email over WhatsApp/SMS to <strong>+1 2156920610</strong></li>
            </ul>
        </main>
    )
}

export default PayPage;
import React, { useEffect, useState } from "react";
import './EventPage.css'


const EventPage = ({eId}) => {

    let [loadingState, setLoadingState] = useState('pending');

    const tugozcallback = () => {
        setLoadingState('success')
    }

    setTimeout(() => {
        setLoadingState('success')
    }, 8000)

    let loadingP;

    useEffect(() => {
        const tugoz = new window.Tugoz(170, false, "ticket-widget");
		tugoz.embed(eId);
    }, [eId])

    if(loadingState === 'pending') {
        return (
            <main className="cc-ev-main">                
                <div id="ticket-widget" style={{padding: '150px 10px 0px 10px'}}></div>
                <p className='cc-loading' >Loading...  Please wait...</p>
            </main>
        )
    }

    if(loadingState === 'success') {
        return (
            <main className="cc-ev-main">                
                <div id="ticket-widget" style={{padding: '150px 10px 0px 10px'}}></div>
                <p className='cc-loading' ></p>
            </main>
        )
    }


    if(loadingState === 'rejection') {
        return (
            <main className="cc-ev-main">                
                <div id="ticket-widget" style={{padding: '150px 10px 0px 10px'}}></div>
                <p className='cc-loading' >Error loading ticketing page.  Please try again.</p>
            </main>
        )
    }
}

export default EventPage;


import './UPIPage.css';

const UPIPage = () => {
    return (
        <main className='cc-uf-main'>
            <h1>UPI Payment for concerts in INR</h1><p/>            
            <h2>Steps to pay using GPay/PhonePe/PayTM</h2><p/>
            <ul>
                <li>Open GPay/PhonePe/PayTM app</li>
                <li>Select New Payment</li>
                <li>Select UPI ID option</li>
                <li>Enter UPI ID <strong>teamcadenceentertainment@okaxis</strong>. For PayTM you can use Cadence phone number <strong>+91 9769142163</strong></li>
                <li>Enter ticket amount Rs 499 for single concert, or Rs 3000 for the 12 concert annual pass.</li> 
                <li>Click Pay button</li> 
                <li>For additional confirmation, after making payment, please send your name, email over WhatsApp to <strong>+1 2156920610</strong></li>
            </ul>
        </main>
    )
}

export default UPIPage;
import './AboutUsPage.css'

const AboutUsPage = () => {
    return (
        <main className="cc-au-main">
            <h1>About Us</h1>
            <div>
                Cadence Entertainment is a business registered in the state of Pennsylvania. 
                Its also a registered business in India. We are an entertainment company focused on 
                bringing high quality musical concerts and high quality theatre. 
                We take pride in connecting top-rated artists with audiences around the world. 
                Our events include online live streaming events and live stage shows. 
                Since 2019 we have presented 60 plus shows with top artists across the world. 
                Our mission is to offer High quality entertainment and bring you an experience rich 
                in spontaneity and interaction.
            </div>

            <div>
                <strong>Contact Us</strong>
                <ul>
                    <li>
                        <u>Headquarters - Pennsylvania - United States</u>
                        <p>1102 Summit Ct, Lansdale, PA 19446 Tel: 1-215-692-0610 Email: support@TeamCadenceEntertainment.com</p>
                    </li>
                    <li>
                        <u>India</u>
                        <p>Plot No-26, Sector 35-I, Kharghar, Raigad, Maharastra, India Tel:+91 9769142163</p>
                    </li>
                </ul>
            </div>

            <div>
                <strong>Shipping Policy</strong>
                <br/>
                    All event tickets are issued electronically at the time of purchase on the website. 
                    The tickets are non-transferrable and allow access to the shows for which the tickets 
                    were purchased. 
                    Write to us at support@TeamCadenceEntertainment.com in case of questions or concerns.
                
            </div>
        </main>
    )
}

export default AboutUsPage;
import { useState, useEffect, useLayoutEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import LivePage from './Pages/LivePage/LivePage';
import VodPage from './Pages/VodPage/VodPage';
import VodPackagePage from './Pages/VodPackagePage/VodPackagePage';
import EventPage from './Pages/EventPage/EventPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage';
import PrivacyPage from './Pages/PrivacyPage/PrivacyPage';
import RefundPage from './Pages/RefundPage/RefundPage';
import UPIPage from './Pages/UPIPage/UPIPage';
import PayPage  from './Pages/PayPage/PayPage';
import JGPage from './Pages/JGPage/JGPage';
import events from './events.json';
import {createBrowserHistory} from 'history'

function App() {
  const history = createBrowserHistory();
  const [liveEventPages, setLiveEventPages] = useState([]);
  const [vodEventPages, setVodEventPages] = useState([]);
  const [jgEventPages, setJGEventPages] = useState([]);
  const [vodPackageEventPages, setVodPackageEventPages] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [vodEvents, setVodEvents] = useState([]);
  const [jgEvents, setJGEvents] = useState([]);
  const [vodPackageEvents, setVodPackageEvents] = useState([]);

 
  useLayoutEffect(()=> {    
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];    
    if (path) {
      history.replace(path);
    }
  },[]);

  useEffect(() => {
      if(liveEvents.length === 0) {
          setLiveEvents(events.liveEvents);
      }
  }, [liveEvents]); 

  useEffect(() => {
      if(vodEvents.length === 0) {
          setVodEvents(events.vodEvents);
      }

  }, [vodEvents])

  useEffect(() => {
    if(jgEvents.length === 0) {
        setJGEvents(events.jgEvents);
    }
}, [jgEvents]); 

  useEffect(() => {
    if(vodPackageEvents.length === 0) {
        setVodPackageEvents(events.vodPackageEvents);
    }

}, [vodPackageEvents])

  useEffect(() => {
    setLiveEventPages(events.liveEvents.map((event, index) => <Route key={index} path={`/${event.folderName}`}> <EventPage eId={event.eId} /> </Route>));
    
    setVodEventPages(events.vodEvents.map((event, index) => <Route key={index} path={`/${event.folderName}`}> <EventPage eId={event.eId} /> </Route>));

    setJGEventPages(events.jgEvents.map((event, index) => <Route key={index} path={`/${event.folderName}`}> <EventPage eId={event.eId} /> </Route>));

    setVodPackageEventPages(events.vodPackageEvents.map((event, index) => <Route key={index} path={`/${event.folderName}`}> <EventPage eId={event.eId} /> </Route>));
  }, [])

    return (
      <Router history={history}>
        <div id="cc-app">
          <Navbar />
          {/* Redirects and component managment below */}
            <Switch >                            
              <Route path="/live" >
                <LivePage events={liveEvents} />
              </Route>
              <Route path="/vod" >
                <VodPage events={vodEvents} />
              </Route>
              <Route path="/vod-packages" >
                <VodPackagePage events={vodPackageEvents} />
              </Route>
              <Route path="/jaipur-gunijankhana" >
                <JGPage events={jgEvents} />
              </Route>
              <Route path="/about" component={AboutUsPage} />
              <Route path="/privacy" component={PrivacyPage} />
              <Route path="/refund" component={RefundPage} />
              <Route path="/upi" component={UPIPage} />\
              <Route path="/pay" component={PayPage} />\
              {liveEventPages}
              {vodEventPages}                 
              {vodPackageEventPages}
              {jgEventPages}
              <Route path="/index.html">
                <Redirect to="/live" />
              </Route>             
              <Route exact path="/">
                <Redirect to="/live" />
              </Route>             
            </Switch>
          <Footer />
        </div>
    </Router>
    );

}

export default App;

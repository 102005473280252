import { Link } from 'react-router-dom';
import './Footer.css';
import facebookImg from '../../Images/facebook.png';
import instagramImg from '../../Images/instagram.png';

const Footer = () => {
    return (
        <footer>
            <div className="cc-info">
                <Link to="/about">About</Link>
                <Link to="privacy">Privacy Policy</Link>
                <Link to="refund">Refund Policy</Link>
                <a id="events4" data-tooltip6="Join Mailing List and get Event Notifications" target="_blank"
  href="https://bit.ly/CadenceSubscribe">Subscribe</a>
                <a id="events4" data-tooltip6="Our facebook" target="_blank"
  href="https://www.facebook.com/TeamCadenceEntertainment/"><i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
<a id="events5" data-tooltip7="Our instagram" target="_blank"
  href="https://www.instagram.com/Cadence.entertainment/"><i className="fa fa-instagram fa-2x"></i></a>
                
                <div className="g-ytsubscribe" data-channelid="UCeUBUuz5jIy-5eG1ysVh1pA" data-layout="full" data-theme="dark" data-count="hidden"></div>
                <a id="events4" data-tooltip6="Unsubscribe from our mailing list" target="_blank"
  href="https://bit.ly/CadenceEmailUnsubscribe">Unsubscribe</a>
            </div>

            <div className="credits">
                <p>&copy; Cadence Entertainment 2024</p>
                <p>Website by: Advay Palnitkar</p>
            </div>
        </footer>
    )
}

export default Footer;
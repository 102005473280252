import './TileList.css';
import Tile from '../Tile/Tile';

const TileList = ({list}) => {
    const tiles = list.filter((event) => {
        return event.displayTile;
    }).map((event, index) => <Tile key={index} event={event} />)
    return (
        <div className="event-list">
            {tiles}
        </div>
    )
}

export default TileList
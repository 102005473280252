import React from 'react';
import './JGPage.css';
import TileList from '../../Components/TileList/TileList';

const JGPage = ({events}) => {

    return (
        <main className="cc-jg-root">
            <div className="cc-jg-video-container">
            <p><strong>Jaipur Gunijankhana</strong> is a space in Pune that is dedicated to promoting 
            Indian classical music through various initiatives. Watch the video to learn more about the 
            collaboration with Cadence Entertainment and scroll below to view list of events available as video on demand.
            </p>
            <iframe src="https://www.youtube.com/embed/U2EvzOzOGuo" title="Jaipur Gunijankhana Collaboration" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</div>
            <div className="cc-jg-main">
            <p className='cc-jg-tile-heading'>Available VOD Rentals. Click on the poster image for details.</p>
            <TileList list={events} />
            </div>
        </main>
    )
}

export default JGPage;
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../Images/logo.png'

const Navbar = () => {
    let menu;
    let nav;
    let navLinks;

    const openMenu = () => {
        navLinks.classList.toggle("nav-active");
        menu.classList.toggle("toggle");
    }

    const closeNav = () => {
        menu.classList.remove("toggle");
        navLinks.classList.remove("nav-active");
    };

    return (
        <nav className="cc-nav" ref={el => (nav = el)}>
            <Link to="/live" className="logo">
                <img src={logo} alt="logo" className="logoImage" />    
                <h5 className="slogan">Spontaneity, interaction, and high quality</h5>            
            </Link>
            
                <ul className="cc-nav-links" ref={el => (navLinks = el)}>
                    <Link to="/live" onClick={closeNav}>
                        <li>Live Events</li>
                    </Link>

                    <Link to="/vod" onClick={closeNav} >
                        <li>VOD</li>
                    </Link>

                    <Link to="/vod-packages" onClick={closeNav} >
                        <li>VOD Packages</li>
                    </Link>

                    <Link to="/jaipur-gunijankhana" onClick={closeNav} >
                        <li>Jaipur Gunijankhana</li>
                    </Link>

                    <Link to="/ccc" onClick={closeNav} >
                        <li>Connoisseurs Club</li>
                    </Link>
                </ul>

            <div onClick={openMenu} className="menu" ref={el => (menu = el)}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
		    </div>
        </nav>
    )
}  

export default Navbar;
import './RefundPage.css';

const RefundPage = () => {
    return (
        <main className="cc-rf-main">
            <h1>Our Refund Policy</h1>
            <p>Refund Policy: All sales are final, non-refundable, and non-transferrable. No refund will be given once the booking is completed. All online shows can be enjoyed by entire family, but live Streaming concurrency is subject to number of tickets purchased, or country specific restrictions.
            <br /><br />Cancellation Policy: If due to unavoidable circumstances such as event cancellation, or any Act of God event is cancelled, then the amount will be refunded after deducting fees.
            </p>
        </main>
    )
}

export default RefundPage;
import React from 'react';
import './VodPage.css';
import TileList from '../../Components/TileList/TileList';

const VodPage = ({events}) => {

    return (
        <main className="cc-vod-root">
            <div className="cc-vod-video-container">
            <p><strong>Cadence Entertainment</strong> has presented 75 plus professional concerts featuring top hindustani classical and semi classical artists from India.
            Professional HD quality recordings of these concerts with mutli camera set up is available to rent as video on demand. 
            Watch the video to preview some of the content and scroll below to view list of events available as video on demand. 
            You can also visit our <a href="https://www.youtube.com/c/CadenceEntertainment">YouTube Channel</a> to view clips of the past concerts.
            </p>
            <iframe src="https://www.youtube.com/embed/hOLDrpbUe_w" title="Ustad Waseem Ahmed Khan" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</div>
            <div className="cc-vod-main">
            <p className='cc-vod-tile-heading'>Available VOD Rentals. Click on the poster image for details.</p>
            <TileList list={events} />
            </div>
        </main>
    )
}

export default VodPage;
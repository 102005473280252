import React from 'react';
import './VodPackagePage.css';
import TileList from '../../Components/TileList/TileList';

const VodPackagePage = ({events}) => {

    return (
        <main className="cc-vod-pkg-root">
            <div className="cc-vod-pkg-video-container">
            <p><strong>Cadence Entertainment</strong> has presented 75 plus professional concerts featuring top hindustani classical and semi classical artists from India.
            Professional HD quality recordings of these concerts with mutli camera set up is available to rent as video on demand. 
            Watch the video to preview some of the content and scroll below to view list of events available as video on demand. 
            You can also visit our <a href="https://www.youtube.com/c/CadenceEntertainment">YouTube Channel</a> to view clips of the past concerts.
            </p>
            <iframe src="https://www.youtube.com/embed/tKx9H3y8qg4" title="Our Journey" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</div>
            <div>
            <p className='cc-vod-pkg-tile-heading'>Available VOD Pacakge Rentals. Click on the poster image for details. For custom 4 VOD packages write to us at support@teamcadenceentertainment.com</p>
            <TileList list={events} />
            </div>
        </main>
    )
}

export default VodPackagePage;